import { cx } from '@emotion/css';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { forwardRef, ReactNode, RefObject, useEffect, useState } from 'react';

import { Input } from '../Forms';
import * as S from './styles';
import {
  getDefaultCountry,
  getPhoneCodeByIso,
  numberToPhoneType,
  phoneCountriesOptions,
  phoneTypeToNumber,
  toCountryOption,
  type CountryOptionType,
} from './utils';

type PhoneInputProps = {
  onChange: (phoneNumber: string) => void;
  name: string;
  defaultValue?: string;
  defaultCountryFlag?: string;
  error?: string;
  label?: string | ReactNode;
  className?: string;
  dataTestid?: string;
  inputRef?: string | ((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement> | null | undefined;
};

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>((props, ref) => {
  const {
    defaultValue,
    defaultCountryFlag,
    label,
    className,
    name,
    error,
    onChange,
    inputRef,
    dataTestid = 'phone-input',
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [selectedCountryObject, setSelectedCountryObject] = useState<CountryOptionType>();

  useEffect(() => {
    const { countryCode, input } = numberToPhoneType(defaultValue || '');

    let countryCodeValue = countryCode;
    if (!countryCode && defaultCountryFlag) {
      countryCodeValue = getPhoneCodeByIso(defaultCountryFlag);
    }
    setSelectedCountryObject(toCountryOption(getDefaultCountry(countryCodeValue)));
    setInputValue(input);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DropdownIndicator = () => <ArrowDropDownRounded style={{ fontSize: 24 }} />;

  return (
    <div className={cx(className, 'layout-column layout-align-start-start')} data-testid={dataTestid}>
      {label != null && <div className="mb-8 layout-row layout-align-start-center">{label}</div>}
      <div className="layout-column layout-align-start-start width-100">
        <div className="layout-row layout-align-start-center width-100">
          <S.PhoneInputSelect
            className="mr-8 flex-33"
            classNamePrefix="react-select"
            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            value={selectedCountryObject}
            onChange={(option: unknown) => {
              const typedOption = option as unknown as CountryOptionType;

              setSelectedCountryObject(typedOption);
              const value = phoneTypeToNumber({
                countryCode: typedOption?.country.code as string,
                number: inputValue,
              });
              onChange(value);
            }}
            errors={error}
            options={phoneCountriesOptions}
            data-testid="phone-input-area"
          />
          <Input
            id={name}
            ref={ref}
            className="flex-66"
            error={!!error}
            type="text"
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.currentTarget.value);
              const value = phoneTypeToNumber({
                countryCode: selectedCountryObject?.country.code as string,
                number: event.currentTarget.value,
              });
              onChange(value);
            }}
            onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
            placeholder={selectedCountryObject?.country.pattern || ''}
            data-testid="phone-input-number"
          />
        </div>

        {error && (
          <div className="flex layout-row layout-align-start-start pt-4">
            <p className="small text-error">{error}</p>
          </div>
        )}
      </div>

      <input name={name} type="hidden" ref={inputRef} />
    </div>
  );
});
